button {
  border: none;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: bold;
  display: inline-block;
  text-decoration: none;
}
button:hover {
  cursor: pointer;
}
button:focus {
  text-decoration: none;
  outline: none;
}
.comment-count {
  color: #25272B;
}
#comment-reveal {
  float: right;
  background-color: #8ec249;
  color: #fff;
}
.comment-box{
  all: revert;
  max-width: 600px;
  margin: 0 auto;
  padding: 0px;
}
.comment {
  border: 0.125rem solid #8ec249;
  border-radius: 15px;
  padding: 1em 10px;
  text-align: left;
}
.comment-body {
  font-style: italic;
  font-size: 1.1em;
  text-align: center;
}
.comment-header {
  font-style: italic;
}
.comment-footer {
  margin-bottom: 1rem;
}
.user-rating {
  padding: 0;
  width: 17px;
  display: inline-flex;
}
.Rating--Star{
  padding: 0px !important;
  display: inline-block;
  width: 100%;
  min-width: 17px;
  height: auto;
}

/* ul {
  list-style-image: url("/images/full-star.png");
}

li {
  display: inline;
} */
