.rating {
  background-color: #E7E7E7;
}
.rating input {
	position:absolute;
	left:-1000000em;
	right:-1000000em;
  display: inline-block;
}

.rating label {
	text-indent: -1000em;
	display: inline-block;
	background-image: url("/images/empty-star.png");
	width: 2em;
	height: 2em;
	background-size: contain;
	background-repeat: no-repeat;
	padding: 2px;
    margin-right: .5em;
}

.rating input:hover + label, .rating input:hover ~ span label, 
.rating input:focus + label, .rating input:focus ~ span label, 
.rating input:checked + label, .rating input:checked ~ span label {
	background-image: url("/images/full-star.png");
}

.rating span {
	display: flex;
	flex-direction: row-reverse;
	justify-content: flex-end;
}

.rating input:focus + labeL {
	outline: 2px dashed #777;
}
  /* User Revews based from examples on https://www.w3schools.com/*/
  /* Three column layout */
  .side {
	float: left;
	width: 15%;
	margin-top: 10px;
  }
  
  .middle {
	float: left;
	width: 70%;
	margin-top: 10px;
  }
  
  /* Place text to the right */
  .right {
	text-align: right;
  }
  
  /* Clear floats after the columns */
  .row:after {
	content: "";
	display: table;
	clear: both;
  }
  .side:after {
	content: "";
	display: table;
	clear: both;
  }
  
  /* The bar container */
  .bar-container {
	width: 100%;
	background-color: #f1f1f1;
	text-align: center;
	color: black;
  }
  
  /* Individual bars */
  .bar-5 {width: 0%; height: 18px; background-color: #d8ecbe;}
  .bar-4 {width: 0%; height: 18px; background-color: #bfdf92;}
  .bar-3 {width: 0%; height: 18px; background-color: #b2d87c;}
  .bar-2 {width: 0%; height: 18px; background-color: #98cb51}
  .bar-1 {width: 0%; height: 18px; background-color: #7ebe25;}
  
  /* Responsive layout - make the columns stack on top of each other instead of next to each other */
  @media (max-width: 400px) {
	.side, .middle {
	  width: 100%;
	}
	/* Hide the right column on small screens */
	.right {
	  display: none;
	}
  }

.flex-row {
	flex-wrap: wrap-reverse;
	
}
.buffer-div {
	clear:both;
	clear:left;
	clear:right;
	/* height:10em; */
}