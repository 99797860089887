* {
    font-family: calibri, sans-serif;
    margin: 0;
    padding: 0;
    --black: #000000;
    --white: #ffffff;
    --green: #8ec249;
    --dark-gray: #25272B;
    --light-gray: #E3E3E3;
    --gray: #606060;
    --red: red;
}

/*Header*/
header {
    flex-shrink: 0;
    background-color: var(--dark-gray);
    color: var(--white);
    padding: 5px 0px;
    display: flex;
    align-items: center;
    flex-wrap: wrap-reverse;
}
.title-header {
    align-items: center;
    flex-direction: column;
    padding: 5px 0px;
    width: 49vw;
    margin: auto;
}
.green-header{
    color: var(--green);
    font-weight: bold;
    border-radius: 10px;
    margin-top: 28px;
    font-size: 20px;
    display: inline-block;
}
.heading-items{
    color: var(--white);
    font-size: 20px;
    font-weight: bold;
    display: inline;
}
.header-title{
    display: inline;
    flex: 0 1 auto;
    padding: 10px 0px;
    text-align: center;
    margin-inline-start: 25vw;
}
.header-sitemap{
    display: flex;
    flex: 0 1 auto;   
    position: relative;
    top: 25px;
    right: 64vw;
}

@media (max-width: 560px){
    .header-sitemap{
        right: 71vw;
    }
}

.heading-order{
    flex: 1;
    padding: 5px 0px;
    text-align: center;
}

/*Body and Main*/

/*Body wraps main, and this duo pushed the footer to the bottom*/

main {
    flex: 1 0 auto;
    background-color: var(--white);
    color: var(--black);
}

.body {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.wrapper {
    margin-left:auto;
    margin-right:auto;
    min-width: 280px;
}

/*Footer*/

footer {
    flex-shrink: 0;
    background-color: var(--dark-gray);
    color: var(--white);
    padding: 5px 0px;
    display: flex;
    align-items: center;
}

.footer-item {
    flex: 1;
    padding: 5px 0px;
    text-align: center;
}

.footer-item img {
    aspect-ratio: 1/1;
    padding: 0px 5px;
}
.horizontal-ul {
    list-style-type: none;
    text-align: center;
    width: 70%;
    margin: 0 auto;
    display: inline-block;
    padding: 0;
}
.horizontal-ul .item{
    display: inline;
}

/*Buttons*/

button {
    background-color: var(--green);
    color: var(--black);
    font-weight: bold;
    height: 30px;
    width: 100px;
    margin: 10px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    border: none;
    border-radius: 5px;
}

#toggle-button {
    background-color: #e8e8e7;
    color: var(--black);
    font-weight: bold;
    height: 35px;
    width: 130px;
    margin: 10px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    border: none;
    border-radius: 5px;
}

.blankbtn {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    width: max-content;
    height:max-content;
    text-align: left;
    overflow: visible;
}

th {
    height: inherit;
    min-width: 75px;
    background-color: var(--green);
    font-size: 22px;
    text-decoration: none;
    font-weight: normal;
    padding: 10px;
}
.th-row {
    background-color: var(--white);
    font-size: 16px;
}

.go-to-top {
    position: fixed;
    cursor: pointer;
    bottom: 30px;
    right: 0;
    color: var(--black);
    background-color: var(--green);
    z-index: 1;
    width: inherit;
    text-align: center;
    height: 45px;
    border-radius: 10px 0 0 10px;
    line-height: 46px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    padding: 10px 15px;
    font-size: 16px;
    font-weight: bold;
}

.bread-crumb {
    background-color: var(--dark-gray);
    color: var(--green);
    border: none;
    font-size: 22px;
    width: auto;
    padding: 0px 0px;
    margin: 0px 0px;
    white-space: nowrap;
    font-weight: bold;
}

.popup {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 2;
}

.popup_content {
    background-color: white;
    position: absolute;
    top: 40%;
    left: 34%;
    width: 30%;
    padding: 20px;
    border-radius: 5px;
    border: 2px solid black;
    z-index: 3;
}

.close {
    color: Black;
    float: right;
}

.close:hover {
    color: var(--green);
    background-color: var(--black);
    cursor: pointer;
}

.loader {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 120px;
    height: 120px;
    margin: -76px 0 0 -76px;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #8ec249;
    border-bottom: 16px solid #8ec249;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

.loader_message {
    position: absolute;
    margin: 20px 0 0 -30px;
    left: 50%;
    top: 60%;
    font-weight: bold;
}
/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/*Circles*/

#circle {
    position: relative;
    width: 100px;
    height: 100px;
    -moz-border-radius:50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    line-height: 100px;
    font-size: 30px;
}

/*Tables*/

table {
   margin: auto;
   border-spacing: 10px;
}

table, td, tr, th {
    border: 2px solid gray;
}

td, .th-row {
    font-size: 16px;
}
    

ul {
    padding-left: 20px;
}

.right-arrow {
    list-style-type: none;
}

.right-arrow::before {
    content: "\25B6";
    display: inline-block; 
    width: 1em; 
    margin-left: -20px;
}

.down-arrow {
    list-style-type: none;
}

.down-arrow::before {
    content: "\25BC";
    display: inline-block; 
    width: 1em; 
    margin-left: -20px;
}

.no-border {
    border: none;
    text-align: center;
    padding: 10px;
}

.pre-tech-header {
    text-align: center;
}

.about-table {
    width: 75%;
    min-width: 305px;
    border: 0px solid #808080;
    border-spacing: 10px;
}

.about-table-row-header {
    background-color: #8ec249;
    font-size: 18px;
    font-weight: bold;
    color: #000000
}

.about-table-row {
    background-color: white;
    text-align: center;
    font-size: 16px;
    padding: 5px;
}

.tech-table {
    width: 75%;
    min-width: 305px;
    border: 0px solid #808080;
    border-spacing: 10px;
}

.tech-table-row-header {
    background-color: #8ec249;
    font-weight: bold;
    color: #000000
}

.tech-table-row {
    background-color: white;
    font-size: 20px;
    text-align: center;
    padding: 5px;
}

/*Print page alignment*/
.printpage {
    border: none;
    text-align: center;
    padding: 10px;
    border-collapse: collapse;
    position: absolute;
    right: 0px;
}

@media screen and (max-width: 700px) {
    .printpage{
        border: none;
        text-align: center;
        padding: 10px;
        border-collapse: collapse;
        position: relative;
        right: 0px;
    }
}

/*Basic Results page searchbar alignment*/
.searchbar-align, .adv-searchbar-align{
    border: none;
    text-align: center;
    padding: 10px;
    border-collapse: collapse;
    position: absolute;
    left: 0px;

}

@media screen and (max-width: 1215px) {
    .searchbar-align{
        border: none;
        text-align: center;
        padding: 10px;
        border-collapse: collapse;
        position: relative;
        left: 0px;
    }
}

/*Advanced Results page searchbar and keywords alignment*/
@media screen and (max-width: 1215px) {
    .adv-searchbar-align{
        border: none;
        padding: 0px;
        border-collapse: collapse;
        position: relative;
       
    }
}
/*Centering Things*/

.centered-text {
    text-align: center;
    padding: 10px;
}

.centered-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.centered-search {
    display: flex;
    flex-direction: column;
}

/*What's New in version text*/
.centered-VersionText {
    text-align: center;
    padding: 10px;
}

/*Right Aligned*/

.right-item {
    float: right;
    padding: 10px;
    text-align: center;
}

/*Fancy divs and such*/

.limited-flex-row {
    display: flex;
    justify-content: space-around;
    margin: 10px auto;
    width: inherit;
    max-width: 600px;
}

.advanced-flex-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 10px 0;
    width: 100%;
}

.error-message {
    font-size: 15px;
}

.flex-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 10px auto;
    width: 90%;
}

.home-flex-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 10px auto;
    width: 90%;
}

.result-row {
    display: flex;
    flex-wrap: wrap;
    margin: 5px auto;
    
    max-width: 600px;
}

.results-left {
    flex: 5;
    padding: 0px 10px;
}

.results-right {
    flex: 1;
    padding: 0px 10px;
    display: flex;
    justify-content: center;
}

.left {
    text-align: left;
}

.table-img {
    object-fit: scale-down;
    width: 100%;
    height: inherit;
}

/*Used in tutorial page*/
.bold-text {
    font-weight: bold;
    margin-top: 20px;
}

.bold-text-video {
    font-weight: bold;
}

.list-text {
    margin-bottom: 20px;
}

/*Used in references*/
.npkgs {
    font-weight: bold;
}

/*Used in community feedback and advanced search*/

.search-row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
}

.search-row-3 {
    display: flex;
    flex-wrap: wrap;
    flex: 3;
    flex-direction: column;
    align-items: center;
}

.search-col {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px;
    width: inherit
}

.search-col label {
    padding-top: 10px;
}

/*util class for full width row of items*/
.flexed-items {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start
}
.zip-width {
    width: 33%;
}
.or-width {
    margin-left: 10px;
    
}

/*used on home page row*/

.row-link {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    max-width: 15%;
}

.flex-link {
    flex: 1;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
}

/*Non-media breakpointed classes*/

.flex-width-paragraph {
    max-width: 50%;
    margin: 0 auto;
}

.flex-width-table{
    max-width: 75%;
    margin: 0 auto;
}

.result-letter {
    font-size: 25px;
}

.result-text {
    font-size: 25px;
}

.buffer {
    padding: 25px;
}

.submission-message {
    margin-top: 10%;
}

/*Forms & Inputs*/

form input {
    width: 100%;
}

form span {
    display: block;
    padding: 0 5px 0 0;
}

input {
    border: solid var(--green) 2px;
    height: 30px;
}

textarea {
    border: solid var(--green) 2px;
    width: 400px;
    resize: none;
}

.mobile-table {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    text-align: center;
    padding: 10px;
}

@media screen and (prefers-color-scheme: light) {
    body {
        background-color: white;
        color: black;
    }
}
@media screen and (max-width:700px) {
    table {
        width: 100%;
        table-layout: fixed;
        border-spacing: 0px;
        border: 1px solid gray;
    }
    td, .th-row {
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0px;
        border: 1px solid gray;
        overflow-wrap: break-word;
    }
    th {
        font-size: 12px;
        min-width: 25px;
        padding: 0px;
        overflow: hidden;
        border: 1px solid gray;
        overflow-wrap: break-word;
    }
    .centered-text {
        padding: 0px;
        overflow: hidden;
        overflow-wrap: break-word;
    }
    .no-border {
        border: 1px solid gray;
        padding: 0px;
    }
    .mobile-table {
        border: 1px solid gray;
        padding: 0px;
        width: 100%;
        align-items: initial;
        display: table;
        flex-direction: initial;
    }
    .mobile-list {
        margin-bottom: 25px;
        font-size: 30px;
    }
    .bread-crumb {
        display: block;
    }
    .twenty-five {
        width: 25%;
    }
    .ten {
        width: 10%;
    }
    .twenty {
        width: 20%;
    }
    .fifteen {
        width: 15%;
    }
    .flex-width-paragraph {
        max-width: 100%;
        padding: 10px;
    }
    .flex-width-table{
        max-width: 100%;
        padding: 10px;
    }
    footer {
        flex-direction: column;
    }
    .footer-item {
        flex: 100%
    }
    /*Targets row on home page*/
    .flex-row {
        flex-direction: column;
        align-items: center;
        margin: 0px auto;
        width: 100%;
    }
    .home-flex-row {
        flex-direction: column;
        align-items: left;
        margin: 0px auto;
        width: 100%;
    }
    .row-link {
        height: 100px;
        display: flex;
        flex-direction: row;
        max-width: 90%;
        padding: 10px;
    }
    /*Targets row link text on home page*/
    .row-link h2 {
        margin-right: 10px;
        font-size: 20px;
        flex: 2;
    }
    .flex-link {
        flex: 100%;
    }
    .table-img {
        flex: 1;
        width: auto;
        height: 100px;
    }
    .buffer {
        padding: 0px;
    }
    textarea {
        border: solid var(--green) 2px;
        width: 100%;
        resize: vertical;
    }
    #feedbackCol{
        width:75%;
    }
}

/*Links*/

a {
    text-decoration: none;
    color: inherit;
}

td a:hover {
    text-decoration: underline;
}

.link {
    color: var(--gray);
    text-decoration: underline;
}

.padding {
    padding: 10px;
}

/* input.error {
    border-color: red;
} */

div.input-error {
    color: red;
    padding-left: 2ch;
    padding-top: .5em;
}

select {
    border: solid #8ec249 2px;
    height: 30px;
}

/*Nav*/

nav {
    padding: 20px;
}

ul.nav-list {
    list-style-type: none;
}

/*Var colors available as classes*/

.black {
    color: var(--black);
}

.white {
    color: var(--white);
}

.green {
    color: var(--green);
    display: inline;
}

.dark-gray {
    color: var(--dark-gray);
}

.light-gray {
    color: var(--light-gray);
}

.gray {
    color: var(--gray);
}

.red {
    color: var(--red);
}
.dropdown {
  color: var(--green);
  display: block;
  float:left;
  width: 100px;
  margin: 15px 0px 0px 30px;
}

/*For row of buttons, adds padding to text and margin to border*/

.bordered {
    margin: 20px;
    padding: 10px;
    border-radius: 5px;
    border: solid black 2px;
}

.sitemap-li {
    padding: 10px;
    /* width: 250px; */
    display: flex;
    font-size: 12px;
    font-weight: bold;
}